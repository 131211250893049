import React, { useState } from "react";

import indiaMap from "assets/images/bosch/india-map.png";

import indicatorleaf from "assets/images/bosch/local-indicator-leaf.png";

import downarrow from "assets/images/bosch/downarrow.png";

import kanhashanthivanam from "assets/images/bosch/kanha-shanthi-vanam.png";

import coimbatoresatellite from "assets/images/bosch/cob_plantation_drive.jpg";

import bangaloresatellite from "assets/images/bosch/bangalore-saletallite.png";

import Boschlayout from "../../boschlayout/inner";

import SEO from "components/seo/index";

import { Link } from "gatsby";

import { Modal, Button } from "react-bootstrap";

import punesoil from "assets/images/bosch/pune-soil.png";

import Carousel from "react-multi-carousel";

import "react-multi-carousel/lib/styles.css";

import filDoc1 from 'assets/pdf/BGSW-HFI Quarterly Report 24.02.24.pdf';
import filDoc2 from 'assets/pdf/CSR engagement report-BGSW 2023.pdf';




export default function BgswReports() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 464,
      },
      items: 2,
      // partialVisibilityGutter: 30,
    },
  };

  const ButtonGroup = ({ next, previous }) => {
    return (
      <div className="carousel-button-group">
        {/* <div className='arrow-navigations custom-arrow-navigation-event'>
          <button className='pr-custom zcaler-top-slide-button' onClick={() => previous()}>
            <i className='icon icon-arrow-left-short'></i>
          </button>
          <button className='pr-rightcustom zcaler-top-slide-button zscalar-right-custom' onClick={() => next()}>
            <i className='icon icon-arrow-right-short'></i>
          </button>
        </div> */}
      </div>
    );
  };

  const [showModal, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <SEO title="BGSW Forest Plantation Drive" />
      <Boschlayout>
        <div className="track-forests-banner track-forests-listing pb-0">
          <div className="breadCrumb">
            <div className="breadCrumbText">
              <Link to="/bgsw">HOME</Link>&nbsp;/&nbsp; Reports
            </div>
          </div>
          <div className="container">

          
            <div className="row">
              <div className="col-lg-12 col-md-12 lftContent" style={{ marginTop: "20px" }}>
                <h3 className="title-theme-heading text-bgsw">
                  {" "}
                  Reports
                </h3>
                <ul>
                <li>
               
                1. BGSW-HFI Quarterly Report Feb 2024   <button className='btn btn-bgswtheme'>
                <a href={filDoc1} download>
                  Download <i className='icon icon-download'></i>
                </a>
              </button>
               
                </li>
                <li>
               <br></br>
                2. CSR engagement report-BGSW 2023   <button className='btn btn-bgswtheme'>
                <a href={filDoc2} download>
                  Download <i className='icon icon-download'></i>
                </a>
              </button>
               
                </li>
                </ul>
                {/* <img className='arrow-padding' src={downarrow} alt='bannar tree' /> */}
              </div>
            
            </div>
          </div>
        </div>
        
      </Boschlayout>
    </>
  );
}
